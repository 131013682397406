<template>
  <pdl-drawer size="23rem" show-close :is-open="isDrawerOpen" @close="handleClose">
    <template #content>
      <retailer-select-input
        :initial-value="geolocation.address"
        :handle-close="handleClose"
        :search="fetchInitialRetailers"
      />
      <pdl-loading :is-loading="isLoading">
        <div class="my-3 space-y-6">
          <div v-if="!retailerList.length" class="text-center" qaid="retailer-drawer-no-results">
            {{ $t('text.search.noResults') }}
          </div>
          <retailer-card
            v-for="(retailer, i) in retailerList"
            v-else
            :key="`retailer-card-${i}`"
            :retailer="retailer"
            :is-selected="retailer.name === selectedRetailer.name"
            :set-selected-retailer="handleRetailerSelected"
            :qaid="`retailer-card-${i}`"
          />
        </div>
        <pdl-loading ref="loadMoreTarget" :is-loading="isLoadingMore" />
      </pdl-loading>
    </template>
  </pdl-drawer>
</template>

<script>
import {PdlDrawer} from '@pedal/pdl-drawer';
import {PdlLoading} from '@pedal/pdl-loading';
import RetailerSelectInput from '@/components/drawer/retailer/RetailerSelectInput.vue';
import RetailerCard from '@/components/drawer/retailer/RetailerCard.vue';
import RetailerApi from '@/api/retailer-api';
import {mapMutations, mapState} from 'vuex';
import {convertPreferredUnitToMeters} from '@/utils/unit-converter';
import {UnitTypes} from '@/constants/unit-types';

const PAGE_SIZE = 15;
const RADIUS_IN_MILES = 200;

export default {
  components: {PdlDrawer, PdlLoading, RetailerSelectInput, RetailerCard},
  props: {
    isDrawerOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      retailerList: [],
      currentPage: 0,
      totalPages: undefined,
      currentQuery: undefined,
      isLoading: false,
      isLoadingMore: false,
      observer: null,
    };
  },
  computed: {
    ...mapState('backend', ['geolocation']),
    ...mapState('user', ['selectedRetailer']),
    ...mapState('pdp', ['pdpSku']),
  },
  mounted() {
    if (this.geolocation.address) this.fetchInitialRetailers(this.geolocation.address);
    const options = {
      root: null,
      rootMargin: '0px',
    };
    this.observer = new IntersectionObserver(this.handleLoadMore, options);
    this.observer.observe(this.$refs.loadMoreTarget.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    ...mapMutations('user', ['setSelectedRetailer']),
    handleRetailerSelected(retailer) {
      this.setSelectedRetailer(retailer);
      this.handleClose();
    },
    handleClose() {
      this.$emit('close');
    },
    async handleLoadMore(intersectionEvent) {
      if (!intersectionEvent[0]?.isIntersecting || !this.retailerList.length) return;
      this.fetchMoreRetailers();
    },
    async fetchInitialRetailers(input) {
      this.isLoading = true;
      try {
        const radius = convertPreferredUnitToMeters(RADIUS_IN_MILES, UnitTypes.IMPERIAL.milesString);
        this.currentPage = 0;
        const params = {
          radius,
          pageSize: PAGE_SIZE,
          currentPage: this.currentPage,
          query: input,
          ecommEnabled: true,
        };
        if (this.pdpSku) {
          params.product = this.pdpSku;
        }
        const {data: retailerData} = await RetailerApi.getRetailers(params);

        this.totalPages = retailerData.pagination?.totalPages;
        this.currentQuery = input;

        this.retailerList = retailerData.stores;
      } catch (error) {
        this.retailerList = [];
      } finally {
        this.isLoading = false;
      }
    },
    async fetchMoreRetailers() {
      if (this.currentPage + 1 >= this.totalPages) return;
      this.currentPage = this.currentPage + 1;
      this.isLoadingMore = true;
      try {
        const radius = convertPreferredUnitToMeters(RADIUS_IN_MILES, UnitTypes.IMPERIAL.milesString);
        const params = {
          radius,
          pageSize: PAGE_SIZE,
          currentPage: this.currentPage,
          query: this.currentQuery,
          ecommEnabled: true,
        };
        if (this.pdpSku) {
          params.product = this.pdpSku;
        }
        const {data: retailerData} = await RetailerApi.getRetailers(params);

        this.retailerList = [...this.retailerList, ...retailerData.stores];
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingMore = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdl-drawer {
  z-index: 1600000001;
}

::v-deep .pdl-drawer__content {
  background-color: var(--gray-05);
}
</style>
